import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loggingOutUserThunkAction } from "redux/user/actions";
import { selectUser } from "redux/user/selectors";

import { alpha, Button, Menu, MenuItem, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { toggleContext } from "layouts/MainLayout";
import mediagenixLogo from "../../assets/img/brand/mediagenix_logo.svg";

import "../../components/sidebar/sidebarBase.css";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

function Header() {
  const { toggle, setToggle } = React.useContext(toggleContext);
  const handleToggle = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("active");
    setToggle(!toggle);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userName } = useSelector(selectUser);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToLoginPage = () => {
    navigate("/auth/login", { replace: true });
  };

  const logoutHandler = () => {
    dispatch(loggingOutUserThunkAction(navigateToLoginPage));
  };

  return (
    <div>
      <nav className="navbar navbar-light justify-content-between px-0 flex-nowrap">
        <div className="navbar-brand">
          <div className="d-flex">
            <div id="content">
              <div
                className={
                  toggle === false ? "navbar-header toggle-btn" : "navbar-header toggle-btn-close"
                }
              >
                <a
                  className="navbar-minimalize minimalize-styl-2 btn btn-danger "
                  id="sidebarCollapse"
                  href="#menu-toggle"
                  onClick={(e) => handleToggle(e)}
                >
                  <i className="fa fa-bars"></i>
                </a>
              </div>
            </div>
            <img
              src={mediagenixLogo}
              alt="logo"
              style={{
                width: "2rem",
                height: "2rem"
              }}
            />
            <div className="ml-2">Business Support Tools</div>
          </div>
        </div>

        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          className="navbar-dropdown-btn"
          style={{
            backgroundColor: "#F6F6F6",
            color: "black"
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <span className="d-none d-sm-inline ">{userName}</span>
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              logoutHandler();
              handleClose();
            }}
            disableRipple
          >
            Sign out
          </MenuItem>
        </StyledMenu>
      </nav>
    </div>
  );
}

export default Header;
