import { Button } from "reactstrap";

const getColumns = (downloadFileHandle, deleteFileHandle) => {
  return [
    {
      Header: "File Name",
      accessor: "file_name",
      width: 300
    },
    {
      Header: "Created",
      accessor: "created_at",
      width: 200
    },
    {
      Header: "Action",
      accessor: "action",
      width: 20,
      Cell: ({ row }) => {
        return (
          <div className="text-center">
            <Button
              color="danger"
              outline
              onClick={() => downloadFileHandle(row?.original?.download_url)}
            >
              Download
            </Button>
            <Button
              color="danger"
              outline
              onClick={() => deleteFileHandle(row?.original?.file_name)}
            >
              Delete
            </Button>
          </div>
        );
      }
    }
  ];
};

export default getColumns;
