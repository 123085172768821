import {
  FETCH_JIRA_PROJECT_LIST_SUCCESS,
  FETCH_DASHBOARD_DATA_REQUEST,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_FAILURE,
  CLEAR_GRID_DASHBOARD_DATA
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  jiraProjectList: [],
  dashboardData: [],
  totalDashboardPages: 0,
  totalDataLength: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JIRA_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        jiraProjectList: action.payload.projects
      };

    case FETCH_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardData: action.payload.data,
        totalDashboardPages: action.payload.totalPages,
        totalDataLength: action.payload.totalDataLength,
        error: ""
      };
    case FETCH_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        dashboardData: [],
        totalDashboardPages: 0,
        totalDataLength: 0,
        error: action.payload
      };

    case CLEAR_GRID_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: [],
        totalDashboardPages: 0,
        totalDataLength: 0
      };
    default:
      return state;
  }
};

export default reducer;
