import { getJiraissueDetailsApi } from "services/JiraIssueDetailsService";

import {
  FETCH_JIRA_ISSUE_DETAILS_REQUEST,
  FETCH_JIRA_ISSUE_DETAILS_SUCCESS,
  FETCH_JIRA_ISSUE_DETAILS_FAILURE
} from "./types";

export const fetchJiraIssueDetailsRequest = () => {
  return {
    type: FETCH_JIRA_ISSUE_DETAILS_REQUEST
  };
};

export const fetchJiraIssueDetailsSucess = (data) => {
  return {
    type: FETCH_JIRA_ISSUE_DETAILS_SUCCESS,
    payload: { data }
  };
};

export const fetchJiraIssueDetailsFailure = (error) => {
  return {
    type: FETCH_JIRA_ISSUE_DETAILS_FAILURE,
    payload: error
  };
};
export const fetchJiraIssueDetailsThunkAction = (id, params, onSuccess = () => {}, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchJiraIssueDetailsRequest());
      const response = await getJiraissueDetailsApi(id, params);

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(fetchJiraIssueDetailsSucess(response.data));
      onSuccess();
    } catch (error) {
      // dispatch(fetchJiraIssueDetailsFailure(error?.response?.data?.message));
      // onError(error?.response?.data?.message);
      console.log(error, "error");
    }
  };
};
