import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import jiraReducer from "./jira/reducer";
import bucketReducer from "./bucketFile/reducer";
import jiraIssueDetailsReducer from "./jiraIssueDetails/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  jira: jiraReducer,
  bucket: bucketReducer,
  jiraIssueDetails: jiraIssueDetailsReducer
});

export default rootReducer;
