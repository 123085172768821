import { toggleContext } from "layouts/MainLayout";
import React from "react";
import "./Style.css";
import "../../components/sidebar/sidebarBase.css";
import Header from "components/Header/Header";

function AlreadyLoggedIn() {
  const { toggle, setToggle } = React.useContext(toggleContext);
  return (
    <>
      <div className={toggle === false ? "px-4 collapsed-sidebar" : "fix-sidebar"}>
        <Header />
        <body>
          <div className="already-logged-in">
            <h2> User Already Logged In!</h2>
            <p>Password cannot be updated.</p>
          </div>
        </body>
      </div>
    </>
  );
}

export default AlreadyLoggedIn;
