import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { Table } from "reactstrap";
import toast from "react-hot-toast";

import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import Header from "components/Header/Header";
import SelectSearch from "components/SelectSearch/SelectSearch";
import AddImage from "components/Modals/AddImage";

import { selectJira } from "redux/jira/selectors";
import { selectUserToken } from "redux/user/selectors";
import { fetchJiraProjectsListThunkAction } from "redux/jira/actions";
import { postBucketFileThunkAction, deleteBucketFileThunkAction } from "redux/bucketFile/actions";
import { fetchBucketListThunkAction } from "redux/bucketFile/actions";
import { selectBucket } from "redux/bucketFile/selectors";

import { rowsPerPageOptions } from "Data/jiraIssueListData";

import getColumns from "./BucketFilesTableColumns";

import leftPageIcon from "../../assets/img/brand/left_page_icon.svg";
import rightPageIcon from "../../assets/img/brand/right_page_icon.svg";
import firstPageIcon from "../../assets/img/brand/first_page_icon.svg";
import lastPageIcon from "../../assets/img/brand/last_page_icon.svg";

import { toggleContext } from "layouts/MainLayout";

import "./BucketFiles.css";
import "../../components/sidebar/sidebarBase.css";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

let selectedproj = null;
function BucketFilesTable() {
  const { jiraProjectList: allProjectsList } = useSelector(selectJira);

  const { isLoading, bucketListData } = useSelector(selectBucket);

  const { toggle, setToggle } = React.useContext(toggleContext);
  const token = useSelector(selectUserToken);

  const dispatch = useDispatch();

  const [projectsList, setProjectsList] = React.useState([]);
  const [issueData, setIssueData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  const [selectedProject, setSelectedProject] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState({
    id: 1,
    label: "10",
    value: 10
  });

  const downloadFileHandle = (url) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
  };

  const deleteFileHandle = (fileName) => {
    const requestBodyFormData = {
      file_name: fileName
    }

    dispatch(
      deleteBucketFileThunkAction(requestBodyFormData, () => {
        dispatch(fetchBucketListThunkAction(onSuccess, onError));
      })
    );

  }

  const columns = useMemo(() => getColumns(downloadFileHandle, deleteFileHandle), []);
  const data = useMemo(() => issueData, [issueData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: INITIAL_PAGE_INDEX, sortBy: [], pageSize: INITIAL_PAGE_SIZE },
      manualSortBy: true,
      disableMultiSort: true
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageCount,
    pageOptions,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state,
    setPageSize
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const selectProjectHandler = (newValue) => {
    selectedproj = newValue;
    setSelectedProject(newValue);
  };

  const handleOpen = () => {
    if (selectedproj === null) {
      toast.error("Please select a project");
      return;
    }
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const addNewImageHandler = (files) => {
    const requestBodyFormData = new FormData();

    requestBodyFormData.append("project_key", selectedproj?.project_key);
    requestBodyFormData.append("file", files[0]);

    dispatch(
      postBucketFileThunkAction(requestBodyFormData, () => {
        dispatch(fetchBucketListThunkAction(onSuccess, onError));
      })
    );
  };

  React.useEffect(() => {
    dispatch(fetchJiraProjectsListThunkAction());
  }, []);

  React.useEffect(() => {
    if (token) {
      dispatch(fetchBucketListThunkAction(onSuccess, onError));
    }
  }, [token]);

  React.useEffect(() => {
    if (allProjectsList.length > 0) {
      setProjectsList([
        ...allProjectsList,
        {
          id: "STANDARD",
          label: "STANDARD",
          project_key: "standard",
          project_name: "Standard",
          value: "Standard"
        }
      ]);
      selectedproj = allProjectsList[0];
      setSelectedProject(allProjectsList[0]);
    }
  }, [allProjectsList]);

  React.useEffect(() => {
    setIssueData(bucketListData);
  }, [bucketListData]);

  const pageDataFrom = pageSize * (pageIndex ? pageIndex : pageIndex) + 1;
  const pageDataTo =
    pageSize * (pageIndex ? pageIndex + 1 : 1) > issueData.length
      ? issueData.length
      : pageSize * (pageIndex ? pageIndex + 1 : 1);

  return (
    <div className={toggle === false ? "px-4 collapsed-sidebar" : "fix-sidebar"}>
      <Header />

      <React.Fragment>
        <div className="jira_filter_container  bucket_listing_container">
          <div className="mr-3">Project</div>
          <SelectSearch
            handleChange={selectProjectHandler}
            style={{
              margin: "0 1rem 0 0",
              width: "300px"
            }}
            isSearching={true}
            placeHolder="Search project"
            options={projectsList}
            value={selectedProject}
          />

          <Button
            onClickHandler={handleOpen}
            style={{
              backgroundColor: "#FFD9DE",
              color: "#E3001B",
              borderRadius: "0px"
            }}
          >
            Upload File
          </Button>
        </div>

        <div className="jira_pagination_container mt-5">
          <div className="d-flex">
            <AddImage
              handleClose={handleClose}
              modalOpen={modalOpen}
              ImageHandler={(file) => {
                addNewImageHandler(file);
              }}
            />
          </div>

          <div className="jira_pagination_item">
            <div className="jira_pagination_subitem">
              <span>Rows</span>
              <SelectSearch
                handleChange={(e) => {
                  setPageSize(Number(e.value));
                  setRowsPerPage(e);
                }}
                value={rowsPerPage}
                style={{
                  width: "5rem",
                  height: "2rem",
                  minHeight: "2rem"
                }}
                placeHolder=""
                options={rowsPerPageOptions}
                isSearching={false}
                isClearing={false}
              />
            </div>
            <div>
              <span>
                {" "}
                {`${pageDataFrom} - ${pageDataTo}`} of {` ${issueData.length}`}
              </span>
            </div>
            <div className="pagination_icon">
              <button disabled={!canPreviousPage} className="pagination_icons">
                <img src={firstPageIcon} alt="first page icon" onClick={() => gotoPage(0)} />
              </button>
              <button disabled={!canPreviousPage} className="pagination_icons">
                <img src={leftPageIcon} alt="left page icon" onClick={() => previousPage()} />
              </button>

              <button disabled={!canNextPage} className="pagination_icons">
                <img src={rightPageIcon} alt="right page icon" onClick={() => nextPage()} />
              </button>

              <button disabled={!canNextPage} className="pagination_icons">
                <img
                  src={lastPageIcon}
                  alt="last page icon"
                  onClick={() => {
                    gotoPage(pageCount - 1);
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div
            className="w-100 d-flex align-items-center justify-content-center"
            style={{
              height: "15rem"
            }}
          >
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{
                    height: "15rem"
                  }}
                >
                  {error}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {issueData.length === 0 ? (
                  <React.Fragment>
                    <div
                      className="w-100 d-flex align-items-center justify-content-center"
                      style={{
                        height: "15rem"
                      }}
                    >
                      No Data Found
                    </div>
                  </React.Fragment>
                ) : (
                  <div>
                    <Table
                      bordered
                      className="mt-3 jira_bucket_table_container "
                      responsive
                      {...getTableProps()}
                    >
                      <thead className="jira_issue_table_header">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                onClick={() => {}}
                                {...column.getHeaderProps()}
                                {...column.getHeaderProps({
                                  style: { width: column.width }
                                })}
                                style={
                                  column.render("Header") === "Type"
                                    ? {
                                        backgroundColor: "#F5F5F5"
                                      }
                                    : null
                                }
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody className="jira_issue_table_body" {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={row.id}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    key={cell.id}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.width
                                      }
                                    })}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
}

export default BucketFilesTable;
