import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";

import Header from "components/Header/Header";

import { fetchJiraIssueDetailsThunkAction } from "redux/jiraIssueDetails/actions";
import { selectJiraIssueDetails } from "redux/jiraIssueDetails/selectors";

import { toggleContext } from "layouts/MainLayout";

import "./IssueDetails.css";

function JiraIssueDetails() {
  const dispatch = useDispatch();
  const { toggle, setToggle } = React.useContext(toggleContext);
  const tabs = {
    comments: {
      tabId: 1,
      tabTitle: "comments"
    },
    history: {
      tabId: 2,
      tabTitle: "history"
    }
  };
  const [tab, setTab] = useState({
    tabId: 1,
    tabTitle: "comments"
  });

  const { jiraIssueDetails, isLoading } = useSelector(selectJiraIssueDetails);
  const [issueDetails, setIssueDetails] = useState(null);
  const { id } = useParams();

  const toggleTab = (index) => {
    setTab(index);
    fetchJiraIssueDetailhandler(index.tabTitle);
  };

  const fetchJiraIssueDetailhandler = (params) => {
    let requestParams = {
      activity_type: params
    };
    dispatch(fetchJiraIssueDetailsThunkAction(id, requestParams));
    
  };
  React.useEffect(() => {
    if (id) {
      fetchJiraIssueDetailhandler(tab.tabTitle);
    }
  }, []);

  React.useEffect(() => {
    setIssueDetails(jiraIssueDetails);
  }, [jiraIssueDetails]);

  const handleTypeBadge = (typeBadge) => {
    switch (typeBadge) {
      //type
      case "IR":
        return { backgroundColor: "#ffe3a6db", color: "#725311" };

      case "B":
        return { backgroundColor: "#c0f5ff", color: "#0c55a4" };

      case "P":
        return { backgroundColor: "#fdccff", color: "rgb(172 0 255)" };

      case "IN":
        return { backgroundColor: "#0088802b", color: "rgb(0 54 38)" };

      //status

      case "New":
        return { backgroundColor: "#c0f5ff", color: "#0c55a4" };

      case "Ready to start":
        return { backgroundColor: "#ffe3a6db", color: "#725311" };

      case "Closed":
        return { backgroundColor: "rgb(255, 203, 203)", color: "rgb(188, 0, 22)" };

      case "Testing":
        return { backgroundColor: "rgb(255 221 252 / 47%)", color: "rgb(158 0 175)" };

      case "Resolved":
        return { backgroundColor: "rgb(245 252 174 / 65%)", color: "rgb(144 108 0)" };

      case "Solution design":
        return { backgroundColor: "rgb(236 245 255)", color: "rgb(0 93 255)" };

      case "In code review":
        return { backgroundColor: "rgb(132 201 187 / 24%)", color: "rgb(0 116 151)" };

      case "Confirmed bug":
        return { backgroundColor: "#79554833", color: "#ac2e00" };

      case "Solution  approved":
        return { backgroundColor: "rgb(199 188 135 / 20%)", color: "rgb(0 120 136)" };

      case "Waiting for 3rd party":
        return { backgroundColor: "rgb(196 129 0 / 20%)", color: "rgb(139 0 0)", width: "18vh" };

      case "On Hold":
        return { backgroundColor: "rgb(0 79 196 / 10%)", color: "rgb(0 112 234)" };

      case "Canceled":
        return { backgroundColor: "rgb(255 32 66 / 17%)", color: "rgb(187 0 0)" };

      case "Delivered":
        return { backgroundColor: "rgb(32 255 79 / 17%)", color: "rgb(0 109 53)" };

      case "In delivery cycle":
        return { backgroundColor: "rgb(95 151 81 / 13%)", color: "rgb(104 129 37)" };

      case "Mediagenix investigating":
        return {
          backgroundColor: "rgb(237 120 255 / 20%)",
          color: "rgb(162 0 158)",
          width: "15vh"
        };

      case "To be merged":
        return { backgroundColor: "rgb(32 201 151 / 23%)", color: "rgb(0 51 59)" };

      case "Waiting for customer":
        return { backgroundColor: "#0088802b", color: "rgb(0 54 38)", width: "15vh" };

      case "Done":
        return { backgroundColor: "#b9fcaea6", color: "#005c15" };

      default:
        return { backgroundColor: "rgb(186 186 186 / 47%)", color: "rgb(16 16 16)" };
    }
  };
  const getActiveClass = (index, className) => (tab.tabId === index ? className : "");

  return (
    <div className={toggle === false ? "px-4 collapsed-sidebar " : "fix-sidebar"}>
      <Header />
      <div className="main">
        <div className="issue_details_header">
          <h2>{issueDetails?.title}</h2>
          <p>{issueDetails?.issue}</p>
        </div>
        <h4
          style={issueDetails?.status ? handleTypeBadge(issueDetails?.status) : null}
          className="issue_status"
        >
          {issueDetails?.status}
        </h4>

        <div className="issue_main_container">
          <div className="issue_lt_container">
            <div className="issue_activity_header">
              <h4>Activity</h4>
            </div>
            <ul className="tab-list">
              <p>Show :</p>
              <li
                className={`tabs ${getActiveClass(1, "active-tabs")}`}
                onClick={() => {
                  toggleTab(tabs?.comments);
                }}
              >
                Comments
              </li>
              <li
                className={`tabs ${getActiveClass(2, "active-tabs")}`}
                onClick={() => {
                  toggleTab(tabs?.history);
                }}
              >
                History
              </li>
            </ul>
            <div className={`content ${getActiveClass(1, "active-content")}`}>
              {isLoading ? (
                <div
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{
                    height: "15rem"
                  }}
                >
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem"
                    }}
                  />
                </div>
              ) : (
                <React.Fragment>
                  {issueDetails && issueDetails.comments && issueDetails.comments.length > 0 ? (
                    issueDetails.comments.map((comment, index) => (
                      <div className="comment_container" key={index}>
                        <div>
                          <div className="issue_details">
                            <h5>{comment.author}</h5>
                            <h4>added a comment - {comment.created}</h4>
                          </div>
                          <div className="issue_comment">
                            <p>{comment.comment}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No comments</p>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className={`content ${getActiveClass(2, "active-content")}`}>
              {isLoading ? (
                <div
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{
                    height: "15rem"
                  }}
                >
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem"
                    }}
                  />
                </div>
              ) : (
                <React.Fragment>
                  {issueDetails && issueDetails.history && issueDetails.history.length > 0 ? (
                    issueDetails.history.map((history, index) => (
                      <div className="comment_container" key={index}>
                        <div>
                          <div className="issue_details">
                            <h5>{history.author}</h5>
                            <h4>made changes - {history.created}</h4>
                          </div>
                          <div className="issue_history">
                            <table style={{ width: "100%" }}>
                              <tr>
                                <th>
                                  <h3>Field</h3>
                                </th>
                                <th>
                                  <h3>Original Value</h3>
                                </th>
                                <th>
                                  <h3>Updated Value</h3>
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <p>{history.field_type}</p>
                                </td>
                                <td>
                                  <p>{history.change_from ? history.change_from : "-"}</p>
                                </td>
                                <td>
                                  <p>{history.change_to ? history.change_to : "-"}</p>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No History</p>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="issue_rt_container">
            <div className="issue_response_details">
              <div className="issue_type">
                <h4>TYPE :</h4>
                <h5
                  style={
                    issueDetails?.issue_type
                      ? handleTypeBadge(issueDetails?.issue_type)
                      : { padding: "0" }
                  }
                >
                  {issueDetails?.issue_type}
                </h5>
              </div>
            </div>
            <div className="issue_response_details">
              <h4>Environment :</h4>
              <h5>{issueDetails?.environment}</h5>
            </div>
            <div className="issue_response_details">
              <h4>CREATED AT :</h4>
              <h5>{issueDetails?.created}</h5>
            </div>
            <div className="issue_response_details">
              <h4>UPDATED AT :</h4>
              <h5>{issueDetails?.updated}</h5>
            </div>
            <div className="issue_response_details">
              <h4>FIRST RESPONSE :</h4>
              <h5>{issueDetails?.first_response}</h5>
            </div>
            <div className="issue_response_details">
              <h4>RESOLVED :</h4>
              <h5>{issueDetails?.resolved}</h5>
            </div>
            <div className="issue_response_details">
              <h4>MGX_PRIO :</h4>
              <h5>{issueDetails?.mgx_prio}</h5>
            </div>
            <div className="issue_response_details">
              <h4>CUST_PRIO :</h4>
              <h5>{issueDetails?.cust_prio}</h5>
            </div>
            <div className="issue_response_details">
              <h4>Fix :</h4>
              <h5>{issueDetails?.fix?.length ? issueDetails?.fix?.join(", ") : "-"}</h5>
            </div>
            <div className="issue_response_details">
              <div className="issue_type">
                <h4>SLA-RESPONSE :</h4>
                <h5
                  style={
                    issueDetails?.sla_response
                      ? null
                      : {
                          backgroundColor: "rgb(255, 203, 203)",
                          color: "rgb(188, 0, 22)"
                        }
                  }
                >
                  {issueDetails?.sla_response ? "True" : "False"}
                </h5>
              </div>
            </div>
            <div className="issue_response_details">
              <h4>SLA-RESPONSE/MAX :</h4>

              <h5>{`${
                issueDetails?.sla_response_minutes ? issueDetails?.sla_response_minutes : "0"
              } ( ${
                issueDetails?.sla_response_hrs ? issueDetails?.sla_response_hrs : "0"
              } hrs ) / ${
                issueDetails?.sla_response_max_minutes
                  ? issueDetails?.sla_response_max_minutes
                  : "0"
              } ( ${
                issueDetails?.sla_response_max_hrs ? issueDetails?.sla_response_max_hrs : "0"
              } hrs )`}</h5>
            </div>
            <div className="issue_response_details">
              <div className="issue_type">
                <h4>SLA-RESOLUTION :</h4>
                <h5
                  style={
                    issueDetails?.sla_resolution
                      ? null
                      : {
                          backgroundColor: "rgb(255, 203, 203)",
                          color: "rgb(188, 0, 22)"
                        }
                  }
                >
                  {issueDetails?.sla_resolution ? "True" : "False"}
                </h5>
              </div>
            </div>
            <div className="issue_response_details">
              <h4>SLA-RESOLUTION/MAX :</h4>
              <h5>{`${
                issueDetails?.sla_resolution_minutes ? issueDetails?.sla_resolution_minutes : "0"
              } ( ${
                issueDetails?.sla_resolution_hrs ? issueDetails?.sla_resolution_hrs : "0"
              } hrs ) / ${
                issueDetails?.sla_resolution_max_minutes
                  ? issueDetails?.sla_resolution_max_minutes
                  : "0"
              } ( ${
                issueDetails?.sla_resolution_max_hrs ? issueDetails?.sla_resolution_max_hrs : "0"
              } hrs )`}</h5>
            </div>

            <div className="issue_response_details">
              <h4>Acknowledgment Time :</h4>
              <h5>{`${
                issueDetails?.mediagenix_investigating_response_minutes ? issueDetails?.mediagenix_investigating_response_minutes : "0"
              }`} 
              </h5>
            </div>

            <div className="issue_response_details">
              <h4>Closed Status Business Response Time :</h4>
              <h5>
              {`${
                issueDetails?.closed_business_response_minutes ? issueDetails?.closed_business_response_minutes : "0"
              }`} 
              </h5>
            </div>

            <div className="issue_response_details">
              <h4>Closed Status Non Business Response Time :</h4>
              <h5>{`${
                issueDetails?.closed_non_business_response_minutes ? issueDetails?.closed_non_business_response_minutes : "0"
              }`} </h5>
            </div>

            <div className="issue_response_details">
              <div className="issue_type">
                <h4>SLA-PROFILE :</h4>
                <h5
                  style={{
                    backgroundColor: "rgb(169 100 255 / 20%)",
                    color: "rgb(78 0 157)",
                    padding: "0.2rem 1rem"
                  }}
                >
                  {issueDetails?.profile}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JiraIssueDetails;
