import axiosInstance from "./AxiosInstance";

export const getJiraProjectListApi = () => axiosInstance.get("jira/projects");

export const getDashboardDataApi = (params) =>
  axiosInstance.get("jira/tickets", {
    params: {
      project_key: params?.project_key,
      prio_type: params?.prio_type,
      from: params?.from,
      to: params?.to,
      page_num: params?.page + 1,
      limit: params?.limit
    }
  });
