import toast from "react-hot-toast";
import { getDashboardDataApi } from "services/JiraServices";
import { getJiraProjectListApi } from "services/JiraServices";

import {
  CLEAR_GRID_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA_FAILURE,
  FETCH_DASHBOARD_DATA_REQUEST,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_JIRA_PROJECT_LIST_SUCCESS
} from "./types";

export const fetchJiraProjectListSuccess = (projects) => {
  return {
    type: FETCH_JIRA_PROJECT_LIST_SUCCESS,
    payload: { projects }
  };
};

export const fetchDashboardDataRequest = () => {
  return {
    type: FETCH_DASHBOARD_DATA_REQUEST
  };
};

export const fetchDashboardDataSuccess = (data, totalPages, totalDataLength) => {
  return {
    type: FETCH_DASHBOARD_DATA_SUCCESS,
    payload: { data, totalPages, totalDataLength }
  };
};

export const fetchDashboardDataFailure = (error) => {
  return {
    type: FETCH_DASHBOARD_DATA_FAILURE,
    payload: error
  };
};

export const clearGridDashboardData = () => {
  return {
    type: CLEAR_GRID_DASHBOARD_DATA
  };
};

export const fetchJiraProjectsListThunkAction = (onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      const response = await getJiraProjectListApi();

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      const transformedData = response.data.map((item) => {
        return {
          id: item.project_key,
          label: item.project_name,
          value: item.project_key,
          ...item
        };
      });

      dispatch(fetchJiraProjectListSuccess(transformedData));
      // onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchDashboardDataThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDashboardDataRequest());
      const response = await getDashboardDataApi(params);

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      const formattedData = response.data.data.map((item) => {
        let slaResolutionSummary;
        if (
          item.sla_resolution_minutes === 0 &&
          item.sla_resolution_hrs === 0 &&
          item.sla_resolution_max_minutes === 0 &&
          item.sla_resolution_max_hrs === 0
        ) {
          slaResolutionSummary = "0 (0 hrs) / 0 (0 hrs)";
        } else if (
          item.sla_resolution_minutes === null &&
          item.sla_resolution_hrs === null &&
          item.sla_resolution_max_minutes === null &&
          item.sla_resolution_max_hrs === null
        ) {
          slaResolutionSummary = "-";
        } else {
          slaResolutionSummary = `${
            item?.sla_resolution_minutes
              ? item?.sla_resolution_minutes
              : item?.sla_resolution_minutes === null
              ? null
              : 0
          } ( ${
            item?.sla_resolution_hrs
              ? item?.sla_resolution_hrs
              : item?.sla_resolution_hrs === null
              ? null
              : 0
          } hrs ) / ${
            item?.sla_resolution_max_minutes
              ? item?.sla_resolution_max_minutes
              : item?.sla_resolution_max_minutes === null
              ? null
              : 0
          } ( ${
            item?.sla_resolution_max_hrs
              ? item?.sla_resolution_max_hrs
              : item?.sla_resolution_max_hrs === null
              ? null
              : 0
          } hrs )`;
        }

        let slaResponseSummary;

        if (
          item.sla_resolution_minutes === 0 &&
          item.sla_resolution_hrs === 0 &&
          item.sla_resolution_max_minutes === 0 &&
          item.sla_resolution_max_hrs === 0
        ) {
          slaResponseSummary = "0 (0 hrs) / 0 (0 hrs)";
        } else if (
          item.sla_response_minutes === null &&
          item.sla_response_hrs === null &&
          item.sla_response_max_minutes === null &&
          item.sla_response_max_hrs === null
        ) {
          slaResponseSummary = "-";
        } else {
          slaResponseSummary = `${
            item?.sla_response_minutes
              ? item?.sla_response_minutes
              : item?.sla_response_minutes === null
              ? null
              : 0
          } ( ${
            item?.sla_response_hrs
              ? item?.sla_response_hrs
              : item?.sla_response_hrs === null
              ? null
              : 0
          } hrs ) / ${
            item?.sla_response_max_minutes
              ? item?.sla_response_max_minutes
              : item?.sla_response_max_minutes === null
              ? null
              : 0
          } ( ${
            item?.sla_response_max_hrs
              ? item?.sla_response_max_hrs
              : item?.sla_response_max_hrs === null
              ? null
              : 0
          } hrs )`;
        
        }

        let mediagenixInvestigatingStatusSummary;
        if (
          item.mediagenix_investigating_response_minutes === 0 &&
          item.mediagenix_investigating_response_hrs === 0
        ) {
          mediagenixInvestigatingStatusSummary = "0,0";
        } else if (
          item.mediagenix_investigating_response_minutes === null &&
          item.mediagenix_investigating_response_hrs === null
        ) {
          mediagenixInvestigatingStatusSummary = "-";
        } else {
          mediagenixInvestigatingStatusSummary = `${
            item?.mediagenix_investigating_response_minutes
              ? item?.mediagenix_investigating_response_minutes
              : item?.mediagenix_investigating_response_minutes === null
              ? null
              : 0
          }`;
        
        } 

        let closedBusinessResponseSummary;
        if (
          item.closed_business_response_minutes === 0 &&
          item.closed_business_response_hrs === 0
        ) {
          closedBusinessResponseSummary = "0,0";
        } else if (
          item.closed_business_response_minutes === null &&
          item.closed_business_response_hrs === null
        ) {
          closedBusinessResponseSummary = "-";
        } else {
          closedBusinessResponseSummary = `${
            item?.closed_business_response_minutes
              ? item?.closed_business_response_minutes
              : item?.closed_business_response_minutes === null
              ? null
              : 0
          }`;
        
        } 

        let closedNonBusinessResponseSummary;
        if (
          item.closed_non_business_response_minutes === 0 &&
          item.closed_business_response_hrs === 0
        ) {
          closedNonBusinessResponseSummary = "0,0";
        } else if (
          item.closed_non_business_response_minutes === null &&
          item.closed_non_business_response_hrs === null
        ) {
          closedNonBusinessResponseSummary = "-";
        } else {
          closedNonBusinessResponseSummary = `${
            item?.closed_non_business_response_minutes
              ? item?.closed_non_business_response_minutes
              : item?.closed_non_business_response_minutes === null
              ? null
              : 0
          }`;
        
        } 

        return {
          ...item,
          type: item.issue_type,
          sla_resolution_max: slaResolutionSummary,
          sla_response_max: slaResponseSummary,
          mediagenix_investigating_response_summary : mediagenixInvestigatingStatusSummary,
          closed_business_response_summary : closedBusinessResponseSummary,
          closed_non_business_response_summary : closedNonBusinessResponseSummary,
          sla_update_time: true,
          profile: item?.profile,
          fix: item?.fix?.join(", ")
        };
      });

      dispatch(
        fetchDashboardDataSuccess(
          formattedData,
          response.data.count / params?.limit,
          response.data.count
        )
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchDashboardDataFailure(error?.response?.data?.message));
      onError(error?.response?.data?.message);
    }
  };
};
