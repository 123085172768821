import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { refreshState } from "redux/user/actions";

import AuthLayout from "layouts/AuthLayout";
import MainLayout from "layouts/MainLayout";

import "./assets/css/App.css";
import "flatpickr/dist/themes/material_green.css";
import { selectUserToken } from "redux/user/selectors";

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken) || localStorage.getItem("token");

  const refreshStateHandler = () => {
    const token = localStorage.getItem("token");
    const userName = localStorage.getItem("username");
    dispatch(refreshState({ token, userName }));
  };

  useEffect(() => {
    refreshStateHandler();
  }, []);

  return (
    <Routes>
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/admin/*" element={<MainLayout />} />
      <Route path="/" element={<Navigate to="/auth/login" />} />
      {token ? (
        <Route path="*" element={<Navigate to="/admin/pageNotFound" />} />
      ) : (
        <Route path="*" element={<Navigate to="/auth/login" />} />
      )}
    </Routes>
  );
};

export default App;
