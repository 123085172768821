/* eslint-disable no-undef */
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AES, enc } from "crypto-js";

import { fetchLoginUserThunkAction } from "redux/user/actions";

import usePasswordShow from "hooks/usePasswordShow";

import image1 from "../../assets/img/brand/login_background.png";
import image2 from "../../assets/img/brand/mediagenix_logo.png";
import image3 from "../../assets/img/brand/M_logo_image.png";
import showPassword from "../../assets/img/theme/show-password-icon.png";
import hidePassword from "../../assets/img/theme/hide-password-icon.png";

import "../Login/Login.css";
import { resetPasswordThunkAction } from "redux/user/actions";

function ResetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    passwordShown: passwordShown1,
    togglePasswordVisiblity: togglePasswordVisiblity1,
    type: type1
  } = usePasswordShow();

  const {
    passwordShown: passwordShown2,
    togglePasswordVisiblity: togglePasswordVisiblity2,
    type: type2
  } = usePasswordShow();

  const navigateToLoginPage = () => {
    navigate("/auth/login", {
      replace: true
    });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required").min(6),
      confirmPassword: Yup.string()
        .required()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref("password")], "Password dose not match")
        })
    }),
    onSubmit: (values) => {
      dispatch(
        resetPasswordThunkAction(
          searchParams.get("token"),
          { password: values.password },
          navigateToLoginPage
        )
      );
    }
  });

  return (
    <div className="d-lg-flex half">
      <div
        className="bg order-1 order-md-2"
        style={{
          backgroundImage: `url(${image1})`,
          position: "relative"
        }}
      >
        <div className="d-flex image_container_content">
          <img src={image3} alt="m_logo" width="82px" height="86px" />
          <div className="image_container_content_title text-uppercase">
            Let&apos;s <br /> Co-Create
          </div>
        </div>
      </div>
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div
            className="row align-items-center justify-content-center"
            style={{
              position: "relative"
            }}
          >
            <div
              className="col-md-7"
              style={{
                position: "static"
              }}
            >
              <div className="login_banner">
                <img src={image2} alt="mediagenix" />
                <div className="mt-1">Business Support Tools</div>
              </div>
              <h3 className="login_form_title">Reset Password</h3>
              <p className="mb-4 login_form_description">Please enter your new Password.</p>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group last mb-2 position-relative">
                  <input
                    type={type1}
                    name="password"
                    placeholder="Password"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {passwordShown1 ? (
                    <img
                      src={hidePassword}
                      alt="hide-password"
                      className="position-absolute password_icon"
                      onClick={togglePasswordVisiblity1}
                    />
                  ) : (
                    <img
                      src={showPassword}
                      alt="show-password"
                      className="position-absolute password_icon"
                      onClick={togglePasswordVisiblity1}
                    />
                  )}
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-danger mb-2">{formik.errors.password}</div>
                ) : null}
                <div className="form-group last mb-2 position-relative">
                  <input
                    type={type2}
                    className="form-control"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                  />
                  {passwordShown2 ? (
                    <img
                      src={hidePassword}
                      alt="hide-password"
                      className="position-absolute password_icon"
                      onClick={togglePasswordVisiblity2}
                    />
                  ) : (
                    <img
                      src={showPassword}
                      alt="show-password"
                      className="position-absolute password_icon"
                      onClick={togglePasswordVisiblity2}
                    />
                  )}
                </div>
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="text-danger mb-2">{formik.errors.confirmPassword}</div>
                ) : null}
                <button
                  disabled={!formik.isValid || !formik.dirty}
                  className="btn px-5 mt-4"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
