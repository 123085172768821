import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import RequireAuth from "components/RequireAuth";
import Sidebar from "components/sidebar/Sidebar";

import BucketFilesTable from "pages/BucketFilesTable/BucketFilesTable";
import JiraIssueDetails from "pages/JiraIssueDetails/JiraIssueDetails";
import JiraIssueTable from "pages/JiraIssueTable/JiraIssueTable";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import AlreadyLoggedIn from "pages/PageNotFound/AlreadyLoggedIn";

export const toggleContext = React.createContext();

function MainLayout() {
  const [toggle, setToggle] = React.useState(false);
  return (
    <React.Fragment>
      <toggleContext.Provider
        value={{
          toggle,
          setToggle
        }}
      >
        <Sidebar />
        <Routes>
          <Route element={<RequireAuth />}>
            <Route path="jira/issue/list" element={<JiraIssueTable />} />
            <Route path="bucketfiles/list" element={<BucketFilesTable />} />
            <Route path="jira/issue/:id" element={<JiraIssueDetails />} />
            <Route path="forgot-password" element={<AlreadyLoggedIn />} />
            <Route path="pageNotFound" element={<PageNotFound />} />
          </Route>

          <Route path="*" element={<Navigate to="pageNotFound" />} />
        </Routes>
      </toggleContext.Provider>
    </React.Fragment>
  );
}

export default MainLayout;
