import greenRightIcon from "../../assets/img/brand/green_right.svg";
import redRightIcon from "../../assets/img/brand/red_right.svg";

const getColumns = (navigateToIssueDetailsPage) => {
  return [
    {
      Header: "Issues",
      accessor: "issue",
      Cell: ({ row }) => {
        return (
          <span
            style={{
              cursor: "pointer"
            }}
            onClick={() => navigateToIssueDetailsPage(row?.original?.issue_id)}
          >
            {row.values.issue ? row.values.issue : "-"}
          </span>
        );
      }
    },
    {
      Header: "Title",
      accessor: "title"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Environment",
      accessor: "environment"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Created",
      accessor: "created"
    },
    {
      Header: "Updated",
      accessor: "updated"
    },
    {
      Header: "First Response",
      accessor: "first_response"
    },
    {
      Header: "Resolved",
      accessor: "resolved",
      Cell: ({ row }) => {
        return <>{row.values.resolved ? row.values.resolved : ""}</>;
      }
    },
    {
      Header: "MGX_Prio",
      accessor: "mgx_prio"
    },
    {
      Header: "Cust_Prio",
      accessor: "cust_prio"
    },
    {
      Header: "SLA-Response",
      accessor: "sla_response",
      Cell: ({ row }) => {
        return (
          <>
            {row.values.sla_response ? (
              <img src={greenRightIcon} alt="true" />
            ) : (
              <img src={redRightIcon} alt="false" />
            )}
          </>
        );
      }
    },
    {
      Header: "SLA-Response/MAX",
      accessor: "sla_response_max"
    },
    {
      Header: "SLA-Resolution",
      accessor: "sla_resolution",
      Cell: ({ row }) => {
        return (
          <>
            {row.values.sla_resolution ? (
              <img src={greenRightIcon} alt="true" />
            ) : (
              <img src={redRightIcon} alt="false" />
            )}
          </>
        );
      }
    },
    {
      Header: "SLA-Resolution/MAX",
      accessor: "sla_resolution_max"
    },
    {
      Header: "Acknowledgement Time",
      accessor: "mediagenix_investigating_response_summary",
    },
    {
      Header: "Closed Status Business Response Time",
      accessor: "closed_business_response_summary",
    },
    {
      Header: "Closed Status Non Business Response Time",
      accessor: "closed_non_business_response_summary",
    },
    {
      Header: "Fix",
      accessor: "fix",
    },
    {
      Header: "Profile",
      accessor: "profile",
    }
  ];
};

export default getColumns;
