import React from "react";

function Button(props) {
  const { children, style, onClickHandler, disable = false } = props;
  return (
    <button
      disabled={disable}
      onClick={onClickHandler}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        ...style
      }}
      className="btn"
    >
      {children}
    </button>
  );
}

export default Button;
