import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { selectUserToken } from "redux/user/selectors";

const RequireAuth = () => {
  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const location = useLocation();

  return token ? <Outlet /> : <Navigate to="/auth/login" state={{ from: location }} replace />;
};

export default RequireAuth;
