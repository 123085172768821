import React from "react";

import Uppy from "@uppy/core";
import { DashboardModal, useUppy } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const AddImage = (props) => {
  const { handleClose, modalOpen, ImageHandler } = props;

  const uppy = useUppy(() => {
    const upy = new Uppy({
      autoProceed: false,
      debug: true,
      restrictions: {
        minNumberOfFiles: 1,
        allowedFileTypes: ["text/xml"],
        maxNumberOfFiles: 1
      },
      browserBackButtonClose: true
    }).on("complete", (response) => {
      const files = response.successful.map((file) => file.data);
      ImageHandler(files);
      handleClose();
      upy.cancelAll();
    });

    return upy;
  });

  React.useEffect(() => {
    return () => {
      uppy.close();
    };
  }, []);

  return (
    <>
      <DashboardModal
        showSelectedFiles
        uppy={uppy}
        closeModalOnClickOutside={false}
        closeAfterFinish
        open={modalOpen}
        onRequestClose={handleClose}
        plugins={["Webcam"]}
        proudlyDisplayPoweredByUppy={false}
      />
    </>
  );
};

export default AddImage;
