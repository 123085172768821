export const rowsPerPageOptions = [
  {
    id: 1,
    label: "10",
    value: 10
  },
  {
    id: 2,
    label: "25",
    value: 25
  },
  {
    id: 3,
    label: "50",
    value: 50
  }
];
