import axiosInstance from "./AxiosInstance";

export const postLoggedInUserApi = (userData) => axiosInstance.post("session/login", userData);

export const postLoggedOutUserApi = () => axiosInstance.post("session/logout");

export const forgotPasswordUserApi = (email) => axiosInstance.post("password/reset", email);

export const resetPasswordUserApi = (token, reqBody) =>
  axiosInstance.post("password/update", reqBody, {
    params: {
      token: token
    }
  });
