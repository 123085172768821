export const getJiraIssueTableColumnClass = (column) => {
  switch (column) {
    case "Issues":
      return "issue_table_column";
    case "Title":
      return "title_table_column";
    case "Type":
      return "type_table_column";
    case "Environment":
      return "sla_response_max_table_column";
    case "Status":
      return "status_table_column";
    case "Created":
      return "created_table_column";
    case "Updated":
      return "created_table_column";
    case "First Response":
      return "first_response_table_column";
    case "Resolved":
      return "created_table_column";
    case "MGX_Prio":
      return "created_table_column";
    case "Cust_Prio":
      return "created_table_column";
    case "SLA-Response":
      return "sla_response_table_column";
    case "SLA-Response/MAX":
      return "sla_response_max_table_column";
    case "SLA-Resolution":
      return "sla_resolution_table_column";
    case "SLA-Resolution/MAX":
      return "sla_resolution_max_table_column";
    case "Acknowledgement Time":
      return "mediagenix_investigating_status_response_hrs_table_column";
    case "Closed Status Business Response Time":
      return "closed_status_business_response_hrs_table_column";
    case "Closed Status Non Business Response Time":
      return "closed_status_non_business_response_hrs_table_column";
    case "Fix":
      return "sla_resolution_max_table_column";
    default:
      return "issue_table_column";
  }
};
