/* eslint-disable no-undef */
import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import image1 from "../../assets/img/brand/login_background.png";
import image2 from "../../assets/img/brand/mediagenix_logo.png";
import image3 from "../../assets/img/brand/M_logo_image.png";

import "../Login/Login.css";
import { forgetPasswordEmailThunkAction } from "redux/user/actions";

function ForgotPassword() {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please enter email").email("Invalid email address")
    }),
    onSubmit: (values) => {
      dispatch(forgetPasswordEmailThunkAction({ email: values.email }));
    }
  });

  return (
    <div className="d-lg-flex half">
      <div
        className="bg order-1 order-md-2"
        style={{
          backgroundImage: `url(${image1})`,
          position: "relative"
        }}
      >
        <div className="d-flex image_container_content">
          <img src={image3} alt="m_logo" width="82px" height="86px" />
          <div className="image_container_content_title text-uppercase">
            Let&apos;s <br /> Co-Create
          </div>
        </div>
      </div>
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div
            className="row align-items-center justify-content-center"
            style={{
              position: "relative"
            }}
          >
            <div
              className="col-md-7"
              style={{
                position: "static"
              }}
            >
              <div className="login_banner">
                <img src={image2} alt="mediagenix" />
                <div className="mt-1">Business Support Tools</div>
              </div>
              <h3 className="login_form_title">Forgot Password</h3>
              <p className="mb-4 login_form_description">Please enter your E-mail.</p>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group first">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-mail"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-2">{formik.errors.email}</div>
                  ) : null}
                </div>

                <button disabled={!formik.isValid} className="btn px-5 mt-4" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
