import {
  REFRESH_STATE,
  RESET_IS_SIGNING,
  RESET_IS_SUBMITING,
  SET_FORGET_PASSWORD_USER_EMAIL,
  SET_IS_SIGNING,
  SET_IS_SUBMITING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER
} from "./types";

const initialState = {
  token: null,
  isLoading: false,
  error: "",
  isSigning: false,
  userName: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGEDIN_USER:
      return {
        ...state,
        token: action.payload.token,
        userName: action.payload.userName
      };
    case SET_LOGGEDOUT_USER:
      return {
        ...state,
        ...initialState
      };

    case SET_IS_SIGNING:
      return {
        ...state,
        isSigning: true
      };
    case RESET_IS_SIGNING:
      return {
        ...state,
        isSigning: false
      };
    case SET_IS_SUBMITING:
      return {
        ...state,
        isSubmiting: true
      };
    case RESET_IS_SUBMITING:
      return {
        ...state,
        isSubmiting: false
      };
    case REFRESH_STATE:
      return {
        ...state,
        token: action.payload.token,
        userName: action.payload.userName
      };
    case SET_FORGET_PASSWORD_USER_EMAIL:
      return {
        ...state,
        forgetEmail: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
