/* eslint-disable no-undef */
import React, { useMemo } from "react";
import moment from "moment";
import axios from "axios";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { Form, FormGroup, Input, Label, Table } from "reactstrap";

import Button from "components/Button/Button";
import Header from "components/Header/Header";
import Loader from "components/Loader/Loader";
import SelectSearch from "components/SelectSearch/SelectSearch";
import "../../components/sidebar/sidebarBase.css";

import { fetchJiraProjectsListThunkAction } from "redux/jira/actions";
import { fetchDashboardDataThunkAction } from "redux/jira/actions";
import { selectJira } from "redux/jira/selectors";
import { selectUserToken } from "redux/user/selectors";
import { clearGridDashboardData } from "redux/jira/actions";
import { toggleContext } from "layouts/MainLayout";

import { rowsPerPageOptions } from "Data/jiraIssueListData";
import getColumns from "./JiraIssueTableColumns";

import { getJiraIssueTableColumnClass } from "utils/getJiraColumnClass";

import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/flatpickr.css";
import "./Jira.css";

import clearGridIcon from "../../assets/img/brand/clear_grid_icon.svg";
import exportToExcelIcon from "../../assets/img/brand/export_excel_icon.svg";
import leftPageIcon from "../../assets/img/brand/left_page_icon.svg";
import rightPageIcon from "../../assets/img/brand/right_page_icon.svg";
import firstPageIcon from "../../assets/img/brand/first_page_icon.svg";
import lastPageIcon from "../../assets/img/brand/last_page_icon.svg";
import { env } from "env";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

function JiraIssueTable() {
  const navigate = useNavigate();
  const { toggle, setToggle } = React.useContext(toggleContext);
  const {
    jiraProjectList: allProjectsList,
    dashboardData,
    totalDashboardPages,
    isLoading,
    totalDataLength
  } = useSelector(selectJira);

  const [isDownloading, setIsDownloading] = React.useState(false);
  const token = useSelector(selectUserToken);

  const dispatch = useDispatch();
  const fromDateRef = React.useRef(null);
  const toDateRef = React.useRef(null);

  const [projectsList, setProjectsList] = React.useState([]);
  const [issueData, setIssueData] = React.useState([]);
  const [error, setError] = React.useState(null);

  const [selectedProject, setSelectedProject] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState({
    id: 1,
    label: "10",
    value: 10
  });
  const [fromDate, setFromDate] = React.useState([new Date(moment().subtract(1, "month"))]);
  const [toDate, setToDate] = React.useState([new Date()]);
  const [selectedPrio, setSelectedPrio] = React.useState("cust_prio");
  const [useDB, setUseDB] = React.useState(null);

  const navigateToIssueDetailsPage = (issueId) => {
    navigate(`/admin/jira/issue/${issueId}`);
  };

  const columns = useMemo(() => getColumns(navigateToIssueDetailsPage), []);
  const data = useMemo(() => issueData, [issueData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: INITIAL_PAGE_INDEX, sortBy: [], pageSize: INITIAL_PAGE_SIZE },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalDashboardPages)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageCount,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state,
    setPageSize
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const selectProjectHandler = (newValue) => {
    setSelectedProject(newValue);
  };

  const fetchDashboardDataHandler = () => {
    if (selectedProject) {
      let requestParams = {
        project_key: selectedProject?.project_key,
        prio_type: selectedPrio,
        page: pageIndex,
        limit: pageSize
      };

      if (fromDate.length && toDate.length) {
        requestParams.from = moment(`${fromDate}`).format("YYYY-MM-DD");
        requestParams.to = moment(`${toDate}`).format("YYYY-MM-DD");
      }
      dispatch(fetchDashboardDataThunkAction(requestParams, onSuccess, onError));
    }
  };

  React.useEffect(() => {
    dispatch(fetchJiraProjectsListThunkAction());
  }, []);

  React.useEffect(() => {
    if (token && projectsList.length) {
      fetchDashboardDataHandler();
    }
  }, [pageIndex, pageSize, token, projectsList, selectedPrio]);

  React.useEffect(() => {
    if (allProjectsList.length > 0) {
      setProjectsList(allProjectsList);
      setSelectedProject(allProjectsList[0]);
    }
  }, [allProjectsList]);

  React.useEffect(() => {
    setIssueData(dashboardData);
  }, [dashboardData]);

  const pageDataFrom = pageSize * (pageIndex ? pageIndex : pageIndex) + 1;
  const pageDataTo =
    pageSize * (pageIndex ? pageIndex + 1 : 1) > totalDataLength
      ? totalDataLength
      : pageSize * (pageIndex ? pageIndex + 1 : 1);

  React.useEffect(() => {
    if (fromDate && toDate) {
      if (moment(fromDate[0]).diff(moment(toDate[0])) > 0) {
        setToDate([]);
      }
    }
    if (fromDate.length === 0) {
      toDateRef.current.flatpickr.clear();
      setToDate([]);
    }
  }, [fromDate]);
  const handeExportExcel = () => {
    setIsDownloading(true);
    axios({
      url: `${env.REACT_APP_DEV_BASE_URL || process.env.REACT_APP_DEV_BASE_URL}/jira/export`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        project_key: selectedProject?.project_key,
        from: moment(`${fromDate}`).format("YYYY-MM-DD"),
        to: moment(`${toDate}`).format("YYYY-MM-DD")
      }
    })
      .then((response) => {
        setIsDownloading(false);
        toast.success("Excel file sent via email successfully");
      })
      .catch((error) => {
        setIsDownloading(false);
        toast.error(error?.message || error?.response?.data?.message || "Something went wrong");
      });
  };
  return (
    <div className={toggle === false ? "px-4 collapsed-sidebar" : "fix-sidebar"}>
      <Header />

      <React.Fragment>
        <div className="jira_filter_container">
          <div className="mr-3">Project</div>
          <SelectSearch
            handleChange={selectProjectHandler}
            style={{
              margin: "0 1rem 0 0",
              width: "300px"
            }}
            isSearching={true}
            placeHolder="Search project"
            options={projectsList}
            value={selectedProject}
          />

          <div className="d-flex">
            <div className="d-flex align-items-center position-relative">
              <span>From</span>

              <Flatpickr
                ref={fromDateRef}
                required={true}
                value={fromDate}
                closeMenuOnSelect
                options={{
                  dateFormat: "Y-m-d",
                  disableMobile: true,
                  allowInput: true,
                  onClose: (selectedDates) => {
                    setFromDate(selectedDates);
                  }
                }}
                onChange={(e) => {
                  setFromDate(e);
                }}
                className="flatpickr datetimepicker form-control ml-2 jira_from_to_date"
                placeholder="YYYY-MM-DD"
              />
              <img
                src={clearGridIcon}
                alt="clear grid"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!fromDateRef?.current?.flatpickr) return;
                  fromDateRef.current.flatpickr.clear();
                }}
                className="position-absolute"
                style={{
                  right: "1rem"
                }}
              />
            </div>
            <div className="d-flex align-items-center ml-3 position-relative">
              <span>To</span>
              <Flatpickr
                ref={toDateRef}
                required={true}
                value={toDate}
                closeMenuOnSelect
                options={{
                  dateFormat: "Y-m-d",
                  minDate: fromDate ? new Date(fromDate) : "",
                  disableMobile: true,
                  allowInput: true,
                  onClose: (selectedDates) => {
                    setToDate(selectedDates);
                  }
                }}
                onChange={(e) => {
                  setToDate(e);
                }}
                className="flatpickr datetimepicker form-control ml-2 jira_from_to_date"
                placeholder="YYYY-MM-DD"
              />

              <img
                src={clearGridIcon}
                alt="clear grid"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!toDateRef?.current?.flatpickr) return;
                  toDateRef.current.flatpickr.clear();
                }}
                className="position-absolute"
                style={{
                  right: "1rem"
                }}
              />
            </div>
          </div>

          <Button
            onClickHandler={() => {
              if (pageIndex === 0) {
                fetchDashboardDataHandler();
              } else {
                gotoPage(0);
              }
            }}
            disable={!selectedProject || !fromDate.length || !toDate.length}
            style={{
              backgroundColor: "#E3001B",
              color: "#fff",
              margin: "0 1rem 0 0",
              borderRadius: "0px"
            }}
          >
            Check for issues
          </Button>
        </div>
        <div className="jira_filter_container">
          <Form>
            <FormGroup tag="fieldset" className="jira_radio_form">
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="prioType"
                    className="jira_radio_group"
                    value="mgx_prio"
                    checked={selectedPrio === "mgx_prio"}
                    onClick={(e) => {
                      setSelectedPrio(e.target.value);
                      gotoPage(0);
                    }}
                  />{" "}
                  Use MGX Prio
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="prioType"
                    className="jira_radio_group"
                    value="cust_prio"
                    checked={selectedPrio === "cust_prio"}
                    onClick={(e) => {
                      setSelectedPrio(e.target.value);
                      gotoPage(0);
                    }}
                  />{" "}
                  Use Cust Prio
                </Label>
              </FormGroup>
            </FormGroup>
          </Form>
        </div>
        <div className="jira_pagination_container">
          <div className="d-flex">
            <Button
              onClickHandler={() => {
                setSelectedProject(null);
                fromDateRef.current.flatpickr.clear();
                setToDate([]);
                dispatch(clearGridDashboardData());
              }}
              style={{
                backgroundColor: "#F5F5F5",
                color: "#4E4E4E",
                borderRadius: "0px"
              }}
            >
              <img src={clearGridIcon} alt="clear grid" className="" />
              Clear Grid
            </Button>
            <Button
              onClickHandler={() => {
                handeExportExcel();
              }}
              style={{
                backgroundColor: "#F5F5F5",
                color: "#4E4E4E",
                borderRadius: "0px"
              }}
              disable={!selectedProject || !fromDate.length || !toDate.length || isDownloading}
            >
              <img src={exportToExcelIcon} alt="export to excel" />
              {isDownloading ? "Downloading..." : "Export to Excel"}
            </Button>
          </div>

          <div className="jira_pagination_item">
            <div className="jira_pagination_subitem">
              <span>Rows</span>
              <SelectSearch
                handleChange={(e) => {
                  setPageSize(Number(e.value));
                  setRowsPerPage(e);
                }}
                value={rowsPerPage}
                style={{
                  width: "5rem",
                  height: "2rem",
                  minHeight: "2rem"
                }}
                placeHolder=""
                options={rowsPerPageOptions}
                isSearching={false}
                isClearing={false}
              />
            </div>
            <div>
              <span>
                {" "}
                {`${pageDataFrom} - ${pageDataTo}`} of {` ${totalDataLength}`}
              </span>
            </div>
            <div className="pagination_icon">
              <button disabled={!canPreviousPage} className="pagination_icons">
                <img src={firstPageIcon} alt="first page icon" onClick={() => gotoPage(0)} />
              </button>
              <button disabled={!canPreviousPage} className="pagination_icons">
                <img src={leftPageIcon} alt="left page icon" onClick={() => previousPage()} />
              </button>

              <button disabled={!canNextPage} className="pagination_icons">
                <img src={rightPageIcon} alt="right page icon" onClick={() => nextPage()} />
              </button>

              <button disabled={!canNextPage} className="pagination_icons">
                <img
                  src={lastPageIcon}
                  alt="last page icon"
                  onClick={() => {
                    gotoPage(pageCount - 1);
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div
            className="w-85 d-flex align-items-center justify-content-center"
            style={{
              height: "15rem"
            }}
          >
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div
                  className="w-85 d-flex align-items-center justify-content-center"
                  style={{
                    height: "15rem"
                  }}
                >
                  {error}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {issueData.length === 0 ? (
                  <React.Fragment>
                    <div
                      className="w-85 d-flex align-items-center justify-content-center"
                      style={{
                        height: "15rem"
                      }}
                    >
                      No Data Found
                    </div>
                  </React.Fragment>
                ) : (
                  <div>
                    <Table
                      bordered
                      className="mt-3 jira_issue_table_container"
                      responsive
                      {...getTableProps()}
                    >
                      <thead className="jira_issue_table_header">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                onClick={() => {}}
                                {...column.getHeaderProps()}
                                className={`${getJiraIssueTableColumnClass(
                                  column.render("Header")
                                )} text-dark`}
                                style={
                                  column.render("Header") === "Type"
                                    ? {
                                        backgroundColor: "#F5F5F5"
                                      }
                                    : null
                                }
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody className="jira_issue_table_body" {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={row.id}>
                              {row.cells.map((cell) => {
                                const value = cell.render("Cell")
                                return (
                                  <td
                                    key={cell.id}
                                    {...cell.getCellProps()}
                                    className={`${getJiraIssueTableColumnClass(
                                      cell.render("Header")
                                    )}`}
                                  >
                                    {cell?.value ? cell.render("Cell") : '-'}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
}

export default JiraIssueTable;
