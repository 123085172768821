import toast from "react-hot-toast";
import { forgotPasswordUserApi } from "services/UserServices";
import { resetPasswordUserApi } from "services/UserServices";
import { postLoggedOutUserApi } from "services/UserServices";
import { postLoggedInUserApi } from "services/UserServices";

import {
  REFRESH_STATE,
  RESET_IS_SIGNING,
  RESET_IS_SUBMITING,
  SET_FORGET_PASSWORD_USER_EMAIL,
  SET_IS_SIGNING,
  SET_IS_SUBMITING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER
} from "./types";

export const setLoggedInUser = ({ token, userName }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { token, userName }
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING
  };
};

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING
  };
};

export const refreshState = ({ token, userName }) => ({
  type: REFRESH_STATE,
  payload: { token, userName }
});

export const setIsSubmiting = () => {
  return {
    type: SET_IS_SUBMITING
  };
};
export const resetIsSubmiting = () => {
  return {
    type: RESET_IS_SUBMITING
  };
};
export const fetchLoginUserThunkAction = ({ email, password }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const response = await postLoggedInUserApi({ email: email, password });
      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Logged in successfully.");
      localStorage.setItem("username", response.data.first_name + " " + response.data.last_name);
      localStorage.setItem("token", response?.data?.auth_token);
      dispatch(
        setLoggedInUser({
          token: response?.data?.auth_token,
          userName: response.data.first_name + " " + response.data.last_name
        })
      );
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      const response = await postLoggedOutUserApi();
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("username");
      localStorage.removeItem("isRemember");
      dispatch(setLoggedOutUser());
      toast.success("Logged out successfully.");
      onSuccess();
    } catch (error) {
      localStorage.removeItem("token");
      dispatch(setLoggedOutUser());
      toast.error(error?.response?.data?.message);
    }
  };
};

export const setForgetpasswordUserEmail = (email) => {
  return {
    type: SET_FORGET_PASSWORD_USER_EMAIL,
    payload: email
  };
};

export const forgetPasswordEmailThunkAction = ({ email }) => {
  return async (dispatch) => {
    try {
      const promise = forgotPasswordUserApi({
        email: email
      });
      toast.promise(promise, {
        loading: "Loading...",
        success: "Reset link has been sent to your email.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      console.log(error.response?.data?.message || error.message);
    }
  };
};


export const resetPasswordThunkAction = (token, reqBody, onSuccess) => {
  return async (dispatch) => {
    try {
      const promise = resetPasswordUserApi(token, reqBody);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Password updated successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      onSuccess();
    } catch (error) {
      console.log(error.response?.data?.message || error.message);
    }
  };
};