/* eslint-disable no-undef */
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AES, enc } from "crypto-js";

import { fetchLoginUserThunkAction } from "redux/user/actions";

import usePasswordShow from "hooks/usePasswordShow";

import image1 from "../../assets/img/brand/login_background.png";
import image2 from "../../assets/img/brand/mediagenix_logo.png";
import image3 from "../../assets/img/brand/M_logo_image.png";
import showPassword from "../../assets/img/theme/show-password-icon.png";
import hidePassword from "../../assets/img/theme/hide-password-icon.png";

import "./Login.css";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();

  const token = localStorage.getItem("token");
  const remember = localStorage.getItem("isRemember");

  const [isRemember, setIsRemember] = React.useState(remember?.toLowerCase() === "true" || false);

  const navigateToHome = () => {
    localStorage.setItem("isRemember", isRemember);
    if (isRemember) {
      const envryptedEmail = AES.encrypt(
        formik.values.email,
        process.env.REACT_APP_DEV_CRYPTO_HASH
      ).toString();
      const envryptedPassword = AES.encrypt(
        formik.values.password,
        process.env.REACT_APP_DEV_CRYPTO_HASH
      ).toString();
      localStorage.setItem("email", envryptedEmail);
      localStorage.setItem("password", envryptedPassword);
    }
    navigate("/admin/jira/issue/list");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please enter email").email("Invalid email address"),
      password: Yup.string().required("Please enter password").min(6)
    }),
    onSubmit: (values) => {
      dispatch(
        fetchLoginUserThunkAction(
          { email: values.email, password: values.password },
          navigateToHome
        )
      );
    }
  });

  React.useEffect(() => {
    async function getUser() {
      if (token && remember?.toLowerCase() === "true") {
        await formik.setFieldValue(
          "email",
          AES.decrypt(
            localStorage.getItem("email")?.toString(),
            process.env.REACT_APP_DEV_CRYPTO_HASH
          )?.toString(enc.Utf8)
        );
        await formik.setFieldValue(
          "password",
          AES.decrypt(
            localStorage.getItem("password")?.toString(),
            process.env.REACT_APP_DEV_CRYPTO_HASH
          )?.toString(enc.Utf8)
        );
      }
    }

    getUser();
  }, [token, remember]);

  return (
    <div className="d-lg-flex half">
      <div
        className="bg order-1 order-md-2"
        style={{
          backgroundImage: `url(${image1})`,
          position: "relative"
        }}
      >
        <div className="d-flex image_container_content">
          <img src={image3} alt="m_logo" width="82px" height="86px" />
          <div className="image_container_content_title text-uppercase">
            Let&apos;s <br /> Co-Create
          </div>
        </div>
      </div>
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div
            className="row align-items-center justify-content-center"
            style={{
              position: "relative"
            }}
          >
            <div
              className="col-md-7"
              style={{
                position: "static"
              }}
            >
              <div className="login_banner">
                <img src={image2} alt="mediagenix" />
                <div className="mt-1">Business Support Tools</div>
              </div>
              <h3 className="login_form_title">Sign In</h3>
              <p className="mb-4 login_form_description">
                Welcome Back!
                <br />
                Please enter your details.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group first">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-2">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group last mb-2 position-relative">
                  <input
                    type={type}
                    className="form-control"
                    placeholder="Password"
                    id="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {passwordShown ? (
                    <img
                      src={hidePassword}
                      alt="hide-password"
                      className="position-absolute password_icon"
                      onClick={togglePasswordVisiblity}
                    />
                  ) : (
                    <img
                      src={showPassword}
                      alt="show-password"
                      className="position-absolute password_icon"
                      onClick={togglePasswordVisiblity}
                    />
                  )}
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-danger mb-2">{formik.errors.password}</div>
                ) : null}

                <div className="d-flex mb-3 align-items-center">
                  <label className="control control--checkbox mb-0">
                    <input
                      type="checkbox"
                      name="remember"
                      checked={isRemember}
                      onChange={(e) => {
                        setIsRemember(e.target.checked);
                      }}
                    />
                    <span className="caption">Remember Me</span>
                  </label>
                </div>
                <div className="d-flex mb-2 caption_forgot_password">
                  <Link
                    to="/auth/forgot-password"
                    style={{
                      textDecoration: "none"
                    }}
                  >
                    <span>Forgot Password</span>
                  </Link>
                </div>
                <button disabled={!formik.isValid} className="btn px-5" type="submit">
                  Sign In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
