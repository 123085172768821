import { toggleContext } from "layouts/MainLayout";
import React from "react";
import { Link } from "react-router-dom";
import "./Style.css";
import "../../components/sidebar/sidebarBase.css";
import Header from "components/Header/Header";

function PageNotFound() {
  const { toggle, setToggle } = React.useContext(toggleContext);
  return (
    <>
      <div className={toggle === false ? "px-4 collapsed-sidebar" : "fix-sidebar"}>
        <Header />
        <body>
          <div className="notfound">
            <div className="notfound-404">
              <h1>404</h1>
            </div>
            <h2> Page not found</h2>
            <p>
              The page you are looking for might have been removed or had its name changed or is
              temporarily unavailable.
            </p>
            <Link style={{ textDecoration: "none" }} to="/admin/jira/issue/list">
              Go To Jira Issue
            </Link>
          </div>
        </body>
      </div>
    </>
  );
}

export default PageNotFound;
