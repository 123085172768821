import React from "react";
import Select from "react-select/creatable";

function SelectSearch(props) {
  const {
    handleChange,
    placeHolder,
    value,
    options,
    style = {},
    isSearching = true,
    isClearing = true
  } = props;

  return (
    <>
      <Select
        styles={{
          control: (base) => ({
            ...base,
            border: "1 solid #E5E5E5",
            borderRadius: "0px",
            boxShadow: "none",
            ...style
          }),
          indicatorSeparator: () => ({})
        }}
        className="react-select-style"
        isClearable={isClearing}
        formatCreateLabel={(inputValue) => (
          <>
            <span>
              <b>{inputValue} </b>
              not found
            </span>
          </>
        )}
        onCreateOption={(option) => {}}
        isSearchable={isSearching}
        onChange={handleChange}
        placeholder={placeHolder}
        options={options}
        value={value}
      />
    </>
  );
}

export default SelectSearch;
