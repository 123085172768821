import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import RestrictedAuth from "components/RestrictedAuth";

import Login from "pages/Login/Login";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import { useSelector } from "react-redux";
import { selectUserToken } from "redux/user/selectors";

function AuthLayout() {

  const token = useSelector(selectUserToken) || localStorage.getItem("token");

  return (
    <Routes>
      <Route element={<RestrictedAuth restricted={true} />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      {token ? (
        <Route path="password/update" element={<Navigate to="/admin/forgot-password" />} />
      ) : (
        <Route path="password/update" element={<ResetPassword />} />
      )}
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
}

export default AuthLayout;
