export const SET_IS_SIGNING = "SET_IS_SIGNING";
export const RESET_IS_SIGNING = "RESET_IS_SIGNING";

export const SET_LOGGEDIN_USER = "SET_USER";
export const SET_LOGGEDOUT_USER = "SET_LOGGEDOUT_USER";

export const REFRESH_STATE = "REFRESH_STATE";

export const SET_FORGET_PASSWORD_USER_EMAIL = "SET_FORGET_PASSWORD_USER_EMAIL";

export const SET_IS_SUBMITING = "SET_IS_SUBMITING";
export const RESET_IS_SUBMITING = "RESET_IS_SUBMITING";
