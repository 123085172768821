import toast from "react-hot-toast";
import { getBucketListApi, postBucketFileApi, deleteBucketFileApi } from "services/BucketService";
import {
  FETCH_BUCKETLIST_DATA_REQUEST,
  FETCH_BUCKETLIST_DATA_SUCCESS,
  FETCH_BUCKETLIST_DATA_FAILURE
} from "./types";

export const fetchBucketListRequest = () => {
  return {
    type: FETCH_BUCKETLIST_DATA_REQUEST
  };
};

export const fetchBucketListSuccess = (data) => {
  return {
    type: FETCH_BUCKETLIST_DATA_SUCCESS,
    payload: { data }
  };
};

export const fetchBucketListFailure = (error) => {
  return {
    type: FETCH_BUCKETLIST_DATA_FAILURE,
    payload: error
  };
};

export const fetchBucketListThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBucketListRequest());
      const response = await getBucketListApi();

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      dispatch(fetchBucketListSuccess(response.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchBucketListFailure(error?.response?.data?.message));
      onError(error?.response?.data?.message);
    }
  };
};

export const postBucketFileThunkAction = (postData, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      const promise = postBucketFileApi(postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "File uploaded successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      onSuccess();
    } catch (error) {
      // toast.error(error?.response?.data?.message || error?.message);
    }
  };
};


export const deleteBucketFileThunkAction = (postData, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      const promise = deleteBucketFileApi(postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "File deleted successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      onSuccess();
    } catch (error) {
      // toast.error(error?.response?.data?.message || error?.message);
    }
  };
};