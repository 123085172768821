import React from "react";
import { Spinner } from "reactstrap";

function Loader() {
  return (
    <>
      <Spinner
        color="main-color"
        style={{
          width: "3rem",
          height: "3rem"
        }}
      />
    </>
  );
}

export default Loader;
