import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { selectUserToken } from "redux/user/selectors";

const RestrictedAuth = ({ restricted = false }) => {
  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const location = useLocation();

  return token && restricted ? (
    <Navigate to="/admin/jira/issue/list" replace state={{ from: location }} />
  ) : (
    <Outlet />
  );
};

export default RestrictedAuth;
