import App from "App";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import dotenv from "dotenv";

import store from "./redux/store";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";

dotenv.config();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Toaster position="top-center" gutter={10} />
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
