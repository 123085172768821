import React from "react";
import { Link, useLocation } from "react-router-dom";

import image2 from "../../assets/img/brand/mediagenix_logo.png";
import { toggleContext } from "layouts/MainLayout";

import "./sidebarBase.css";

function Sidebar() {
  const location = useLocation();
  const { toggle, setToggle } = React.useContext(toggleContext);

  const handleClose = () => {
    const sidebar = document.getElementById("sidebar");
    toggle === false ? setToggle(true) : setToggle(false);
    sidebar.classList.remove("active");
  };

  return (
    <>
      <nav id="sidebar">
        <i
          className="fa fa-times sidebar-close-btn"
          onClick={(e) => {
            handleClose(e);
          }}
        ></i>
        <div className="sidebar-header">
          <img src={image2} alt="mediagenix" style={{ width: "200px" }} />
          <div className="mt-1">Business Support Tools</div>
        </div>

        <ul className="list-unstyled components">
          <li
            className={location.pathname === "/admin/jira/issue/list" ? "active" : ""}
            onClick={(e) => handleClose(e)}
          >
            <Link to="/admin/jira/issue/list" className="text-decoration-none text-dark">
              Jira Issues
            </Link>
          </li>
          <li
            className={location.pathname === "/admin/bucketfiles/list" ? "active" : ""}
            onClick={(e) => handleClose(e)}
          >
            <Link to="/admin/bucketfiles/list" className="text-decoration-none text-dark">
              S3 Bucket Files
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
